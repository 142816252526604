import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { SessionData } from 'src/app/models/session-data';

@Injectable()
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  get<T>(path: string, params: HttpParams = new HttpParams(), session: SessionData = null): Observable<T> {
    const request$ = this.http.get(path, { headers: this.getHeaders(session, true), params: params })
      .pipe(catchError(this.handleError), map<T, T>(response => response));

    return request$;
  }

  post<T>(path: string, body: Object = {}, session: SessionData = null): Observable<T> {
    const request$ = this.http.post(path, JSON.stringify(body), { headers: this.getHeaders(session)})
      .pipe(catchError(this.handleError), map<T, T>(response => response));

    return request$;
  }
  
  put<T>(path: string, body: Object = {}, session: SessionData = null): Observable<T> {
    const request$ = this.http.put(path, JSON.stringify(body), { headers: this.getHeaders(session)})
      .pipe(catchError(this.handleError), map<T, T>(response => response));

    return request$;
  }

  delete<T>(path: string, session: SessionData = null): Observable<T> {
    const request$ = this.http.delete(path, { headers: this.getHeaders(session)})
      .pipe(catchError(this.handleError), map<T, T>(response => response));

    return request$;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  private getHeaders(session: SessionData, isGet = false): HttpHeaders {
    const headersConfig = {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Accept': 'application/json'
    };

    if (session) {
      headersConfig['sessionId'] = session.sessionId;
      headersConfig['accessToken'] = session.accessToken;
    }

    if (!isGet) {
      headersConfig['Content-Type'] = 'application/json';
    }

    return new HttpHeaders(headersConfig);
  }
}
