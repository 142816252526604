import { GeoCoord } from 'src/app/models/geo-coord';
import { DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { roleType, genderType, titleType } from 'src/app/config/enum';

export const generalSetting = {
    appName: 'Noxan Timesheet',
    loadingDuration: 10000,
    toastDuration: 5000,
    logSource: 'Noxan.Ionic',
    allowedDistanceFromStore: 1000,
    timeoutForInitDelay: 100
};

// all in second
export const idleSetting = {
    idleTolerance: 60*19,
    idleTimeout: 60*1,
    idleInteruptions: DEFAULT_INTERRUPTSOURCES,  // clicks, scrolls, touches to the document
    keepAliveInterval: 15
};

export const biometricSetting = {
    successCodes: ['SUCCESS', 'BIOMETRIC_SUCCESS'],
    biometricDismissed: 'BIOMETRIC_DISMISSED'
};

export const formSetting = {
    minEmailOrUsernameLength: 1,
    maxEmailOrUsernameLength: 100,
    minEmailLength: 8,
    maxEmailLength: 100,
    minPasswordLength: 8,
    maxPasswordLength: 20
};

export const timesheetSetting = {
    customRosterId: 2,
    defaultLocation : new GeoCoord(-33.872761, 151.205338),  // sydney
    pickerColumns: 5,
    worklogType: {
        workResume: 'workResume',
        break: 'break'
    }
};

export const mapSetting = {
    googleMapId: 'googlemap',
    zoomLevel: 15,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    draggable: false,
    geolocationOptions: {
        enableHighAccuracy: true,
        timeout: 25000
    }
};

export const imageSetting = {
    baseUrl: 'https://api.noxan.com.au/content/images/',
    noxanLogoMain: 'noxan-logo-main.png',
    noxanLogoTop: 'noxan-logo-top.jpg',
    woolworthsLogofile: 'woolworths-40x40.png',
};

export const switchSetting = {
    noSignOffDate: new Date(2020, 9, 18, 0, 0, 0)   // 0: january
};

export const staffSetting = {
    defaultPassword: 'Password01',
    defaultRole: roleType.staff,
    defaultTitle: titleType.mr,
    defaultGender: genderType.male
};