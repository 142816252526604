import { page } from 'src/app/config/constant';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/api/session.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private sessionService: SessionService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add header with basic auth credentials if request is to the api url
        if (request.url.startsWith(environment.apiBaseUrl) &&
            (
                request.url.indexOf('/session/') > 0 || 
                request.url.indexOf('/profile/') > 0 || 
                request.url.indexOf('/system/') > 0
            )
            ) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Basic ${environment.basicAuth}`
                }
            });
        }

        // if response is 401, remove sessions and go to login
        return next
            .handle(request)
            .pipe(tap(() => {},
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.sessionService.removeSession();
                            this.sessionService.removeSession(true);
                            this.router.navigate([page.login]);
                        }
                        return;
                    }
                }
            ));
    }
}