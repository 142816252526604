import { BrowserModule } from '@angular/platform-browser';
import { Device } from '@ionic-native/device/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { FormControl, FormBuilder } from '@angular/forms';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';

import { BasicAuthInterceptor } from './helpers/basic-auth';
import { FormControlService } from './services/formControls/form-control.service';
import { FormValidationRuleProvider } from './services/formControls/form-validation-rule.provider';
import { HttpService } from './services/common/http.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BiometricLoginComponent } from './components/biometric-login/biometric-login.component';

@NgModule({
  declarations: [AppComponent],
    entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    Device,
    FingerprintAIO,
    FormControl,
    FormBuilder,
    Geolocation,
    HttpService,
    StatusBar,
    SplashScreen,
    TouchID,
    FormValidationRuleProvider,
    FormControlService,
    BiometricLoginComponent,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
