import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { colors } from 'src/app/config/constant';
import { generalSetting } from 'src/app/config/settings';

@Injectable({
  providedIn: 'root'
})

export class ToastService {
  constructor(
    public toastController: ToastController
  ) {}

  async presentToast(infoMessage: string, color: string = colors.danger, duration: number = generalSetting.toastDuration): Promise<void> {
    const toast = await this.toastController.create({
      message: infoMessage,
      duration: duration,
      color: color
    });

    toast.present();
  }
}