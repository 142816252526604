import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { FingerprintAIO, FingerprintOptions } from '@ionic-native/fingerprint-aio/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';

import { page } from 'src/app/config/constant';
import { biometricSetting } from 'src/app/config/settings';
import { errorCode, errorMessage } from 'src/app/config/error';
import { text } from 'src/app/config/text';
import { SessionData } from 'src/app/models/session-data';
import { ServiceResponse } from 'src/app/models/service-response';

import { LoadingService } from 'src/app/services/common/loading.service';
import { SessionService } from 'src/app/services/api/session.service';
import { SystemService } from 'src/app/services/api/system.service';
import { ToastService } from 'src/app/services/common/toast.service';
import { capitalise } from 'src/app/util/common-util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-biometric-login',
  templateUrl: './biometric-login.component.html',
  styleUrls: ['./biometric-login.component.scss'],
})
export class BiometricLoginComponent implements OnInit {

  @Input() isAppPaused: boolean;
  @Input() showLoginPage: boolean;
  @Input() session: SessionData;
  @Input() lastSession: SessionData;
  @Output() loginResponse = new EventEmitter<ServiceResponse<SessionData>>();

  text: any;
  alreadyOpened: boolean;
  availableBiometricType: string;

  constructor(
    private modalController: ModalController,
    private router: Router,
    private platform: Platform,
    private faio: FingerprintAIO,
    private touchId: TouchID,
    private loadingService: LoadingService,
    private sessionService: SessionService,
    private systemService: SystemService,
    private toastService: ToastService
  ) {
    this.text = text.component.biometricLogin;
  }

  ngOnInit() {
    this.init();
  }

  init(): void {
    this.alreadyOpened = null;
    this.availableBiometricType = null;
  
    this.platform.ready().then(() => {
      console.log('[BiometricLoginComponent.init] platforms ' + this.platform.platforms());
      
      this.setBiometricLogin();
    });
    console.log('[BiometricLoginComponent.init] initiated');
  }

  setBiometricLogin(): void {
    if (!this.platform.is('mobileweb')) {
      this.faio.isAvailable().then((availableBiometricType: any) => {
        console.log('[BiometricLoginComponent.setBiometricLogin] fingerprintScan available: ' + availableBiometricType);
        this.availableBiometricType = availableBiometricType;
        this.showBiometricLogin(true);
      },
      (error: any) => {
        console.log('[BiometricLoginComponent.setBiometricLogin] fingerprintScan not available: ' + error);

        if (this.platform.is('ios')) {
          this.setTouchIdLogin();
        }
        else {
          console.log('[BiometricLoginComponent.setBiometricLogin] fingerprintscan error: ' + error.message);
          //TODO: enable touchIdNotAvailableWarning for app
          //this.systemService.postLog('BiometricLoginComponent', 'setBiometricLogin', error, errorCode.biometricLogin.fingerprintNotAvailableWarning, this.lastSession, true);
        }
      });
    }
  }

  setTouchIdLogin(): void {
    this.touchId.isAvailable().then((availableBiometricType: any) => {
      console.log('[BiometricLoginComponent.setTouchIdLogin] touchId available: ' + availableBiometricType);
      this.availableBiometricType = availableBiometricType;
      this.showBiometricLogin(false);
    },
    (error: any) => {
      console.log('[BiometricLoginComponent.setTouchIdLogin] touchId error: ' + error.message);
      //TODO: enable touchIdNotAvailableWarning for app
      //this.systemService.postLog('BiometricLoginComponent', 'setTouchIdLogin', error, errorCode.biometricLogin.touchIdNotAvailableWarning, this.lastSession, true);
    });
  }

  showBiometricLogin(isFingerprintScanAvailable: boolean): void {
    if (!this.lastSession || !this.showLoginPage || this.alreadyOpened) {
      console.log(`[BiometricLoginComponent.showBiometricLogin] not showing for showPage: ${this.showLoginPage}, alreadyOpened: ${this.alreadyOpened}`);
      return;
    }

    this.alreadyOpened = true;
    if (isFingerprintScanAvailable) {
      console.log('[BiometricLoginComponent.showBiometricLogin] loading FingerprintScan');

      this.faio.show({title: this.text.fingerprintScanTitle}).then((result: any) => {
        if (result && biometricSetting.successCodes.includes(result.toUpperCase())) {
          this.proceedBiometricLogin(errorCode.biometricLogin.fingerprintScanVerifyError)
        }
        else {
          this.handleErrorResponse('showBiometricLogin', errorMessage.biometricLogin.fingerprintScanVerifyFail, errorCode.biometricLogin.fingerprintScanVerifyError, errorMessage.biometricLogin.fingerprintScanVerifyFail, this.lastSession);
        }
      },
      (error: any) => {
        this.handleFingerprintScanError(error.message);
      });
    }
    else {
      console.log('[BiometricLoginComponent.showBiometricLogin] loading TouchId');
      this.touchId.verifyFingerprint(this.text.touchIdTitle).then((result: any) => {
        this.proceedBiometricLogin(errorCode.biometricLogin.touchIdVerifyError)
      },
      (error: any) => {
        this.handleErrorResponse('showBiometricLogin', error, errorCode.biometricLogin.touchIdVerifyError, errorMessage.biometricLogin.touchIdVerifyFail, this.lastSession);
      });
    }
  }

  proceedBiometricLogin(code: string): void {
    if (this.isAppPaused) {
      this.processBiometricLoginOnAppPause(code);
    }
    else {
      this.processBiometricLogin(code);
    }
  }

  processBiometricLoginOnAppPause(code: string): void {
    this.sessionService.validateSession(this.session).subscribe((response: ServiceResponse<boolean>) => {
      if (response.data) {
        console.log('[BiometricLoginComponent.processBiometricLoginOnAppPause] session validated');
        this.modalController.dismiss();
      }
      else {
        this.sessionService.removeSession();
        this.sessionService.removeSession(true);
        this.router.navigate([page.login]);
      }
    },
    (error: any) => {
      this.handleErrorResponse('processBiometricLoginOnAppPause', error, errorCode.biometricLogin.biometricLoginError, errorMessage.generalError, this.session);
    });
  }

  processBiometricLogin(code: string): void {
    this.loadingService.present('BiometricLoginComponent.processBiometricLogin').then(() => {
      this.sessionService.biometricLogin(this.lastSession, this.availableBiometricType).subscribe((response : ServiceResponse<SessionData>) => {
          this.loginResponse.emit(response);
        },
        (error: any) => {
          this.handleErrorResponse('processBiometricLogin', error, code, errorMessage.generalError, this.lastSession);
        }
      );
    });    
  }

  handleFingerprintScanError(message: string): void {
    this.alreadyOpened = false;
    if (message.toUpperCase() != biometricSetting.biometricDismissed) {
      this.systemService.postLog('BiometricLoginComponent', 'biometricLogin', message, errorCode.biometricLogin.fingerprintScanError, this.lastSession);
    }
    else {
      console.log(`[BiometricLoginComponent.biometricLogin] biometric type ${this.availableBiometricType} dismissed`);
    }
  }

  handleErrorResponse(caller: string, message: string, errorCode: string, toastMessage: string, session: SessionData): void {
    this.alreadyOpened = false;
    this.systemService.postLog('BiometricLoginComponent', caller, message, errorCode, session);

    if (caller != 'showBiometricLogin') {
      this.loadingService.dismiss(`BiometricLoginComponent.${caller}.error`);
    }

    this.toastService.presentToast(toastMessage);
  }

  getNoSessionErrorMessage(): string {
    return (this.availableBiometricType ? capitalise(this.availableBiometricType) : this.text.biometric) + errorMessage.biometricLogin.loginAttemptFail;
  }
}
