export const generalConstants = {
    platformWeb: 'mobileweb'
};

export const storageKey = {
    sessionData: 'sessionData',
    lastSession: 'lastSessionData',
    deviceInfo: 'deviceInfoKey',
    selectedStore: 'selectedStoreKey'
};

export const page = {
    login: '',
    resetPassword: 'reset-password',
    timesheet: 'home/timesheet',
    summary: 'home/summary',
    signoff: 'home/signoff',
    password: 'home/password',
    logout: 'home/logout'
}

export const responseStatus = {
    sucess: 'Success',
    error: 'Error'
};

export const formKey = {
    loginForm: {
        emailOrUsername: 'emailOrUsername',
        password: 'password'
    },
    resetPasswordForm: {
        email: 'email'
    },
    passwordForm: {
        currentPassword: 'currentPassword',
        newPassword: 'newPassword',
        confirmPassword: 'confirmPassword'
    }
};

export const loginType = {
    loginForm: 'loginForm'
};

export const colors = {
    primary: 'primary',
    secondary: 'secondary',
    tertiary: 'tertiary',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
    light: 'light',
    medium: 'medium',
    dark: 'dark'
};

export const roleLevel = {
    staff: 11,
    teamLead: 21,
    manager: 31,
    generalManager: 41,
    executive: 51
};