import { formSetting } from './settings';

export const errorCode = {
    common: {
        getSessionError: 'Error701-GetSessionError',
        emptyResponseError: 'Error702-EmptyResponseError'
    },

    login: {
        loginError: 'Error711-LoginError',
        getDeviceInfoError: 'Error712-GetDeviceInfoError',
        logoutToResetPasswordError: 'Error713-LogoutToResetPasswordError',
        removeCurrentSessionError: 'Error714-RemoveCurrentSessionError',
        removeLastSessionError: 'Error715-RemoveLastSessionError'
    },   
    biometricLogin: {
        biometricLoginError: 'Error721-LoginError',
        touchIdVerifyError: 'Error722-TouchIdVerifyError',
        fingerprintScanVerifyError: 'Error723-FingerprintScanVerifyError',
        fingerprintScanError: 'Error724-FingerprintScanError',
        fingerprintNotAvailableWarning: 'Warning03-FingerprintNotAvailable',
        touchIdNotAvailableWarning: 'Warning04-TouchIdNotAvailable'
    },

    timesheet: {
        getTimesheetError: 'Error721-GetTimesheetError',
        getStoreByGeocodeError: 'Error722-GetStoreByGeocodeError',
        getShiftsByStoreError: 'Error723-GetShiftsByStoreError',
        completeTimesheetReturnFalse: 'Error724-CompleteTimesheetReturnFalse',
        startTimesheetError: 'Error725-StartTimesheetError',
        startTimesheetUnhandledError: 'Error726-StartTimesheetUnhandledError',
        getCompleteTimesheetsError: 'Error727-GetCompleteTimesheetsError',
        getReferenceDataError: 'Error728-GetReferenceDataError',
        getStoresError: 'Error729-GetStoresError',
        deleteShiftError: 'Error730-DeleteShiftError',
    },
    storePicker: {
        getStoreError: 'Error731-GetStoreError'
    },
    createShift: {
        createShiftError: 'Error732-CreateShiftError'
    },
    createStaff: {
        createStaffError: 'Error733-CreateStaffError',
        generateUsernameError: 'Error734-GenerateUsernameError',
        validateUsernameError: 'Error735-ValidateUsernameError'
    },

    summary: {
        getWorklogsError: 'Error741-GetWorklogsError',
        getWorklogsUnhandledError: 'Error742-GetWorklogsUnhandledError',
    },
    summaryTimesheet: {
        getSummaryError: 'Error743-GetSummaryError',
        getSummaryTimesheetsUnhandledError: 'Error744-GetSummaryTimesheetsUnhandledError',
        getSummaryTimesheetsError: 'Error745-GetSummaryTimesheetsError'
    },
    summaryDetails: {
    },

    resetPassword: {
        resetPasswordError: 'Error751-ResetPasswordError',
        resetPasswordUnhandledError: 'Error752-ResetPasswordUnhandledError',
    },
    password: {
        changePasswordError: 'Error753-ChangePasswordError',
        changePasswordUnhandledError: 'Error754-ChangePasswordUnhandledError'
    },

    logout: {
        logoutError: 'Error761-LogoutError',
        completeTimesheetError: 'Error762-CompleteTimesheetError',
        completeTimesheetReturnFalse: 'Error763-completeTimesheetReturnFalse'
    },

    currentTimesheet: {
        completeTimesheetError: 'Error771-CompleteTimesheetError',
        postWorklogError: 'Error772-PostWorklogError',
        postWorklogUnhandledError: 'Error773-PostWorklogUnhandledError',
        getWorklogUnhandledError: 'Error774-GetWorklogUnhandledError'
    },

    weeklySignoff: {
        getSubmissionUnhandledError: 'Error781-GetSubmissionUnhandledError',
        saveSubmissionUnhandledError: 'Error782-SaveSubmissionUnhandledError',
        disableSubmissionUnhandledError: 'Error783-DisableSubmissionUnhandledError',
        deleteTimesheetUnhandledError: 'Error784-DeleteTimesheetUnhandledError'
    },

    admin: {
        getJobsError: 'Error791-GetJobsError'
    }
};

// error message for toast service or logging
export const errorMessage = {
    generalError: 'There was problem with processing your request.<br/>Please try again later.',
    
    login: {
        userNotFound: 'Your login credential is not found',
        loginFail: 'Your login request was not successful',
        invalidLoginRequest: 'Your login request is invalid',
        tooShortPassword: `Password needs to be at least ${formSetting.minPasswordLength} characters`
    },
    resetPassword: {
        resetPasswordFail: 'Your password reset request was not successful',
        invalidEmail: 'Your email address is not valid',
        userNotFound: 'Your email is not found in the system'
    },
    timesheet: {
        sameTimesheetAreadyExist: 'You already added the same timesheet before.',
        InvalidStartDateRequest: 'You need to provide all fields to choose a start date manually',
        startDateLaterThanNow: 'Start date cannot be future date',
        InvalidEndDateRequest: 'You need to provide all fields to choose an end date manually',
        endDateEarlierThanStart: 'End date cannot be earlier than start date',
        endDateLaterThanNow: 'End date cannot be future date',
        shiftNotSelected: 'Please select a shift to start work',
        completeTimesheetFail: 'Your request to complete timesheet was not successful.<br/>Please try again later.',
    },
    summary: {
        getTimesheetFailed: 'There was pro'
    },
    password: {
        changePasswordFail: 'Your change password request was not successful.<br/>Please try again later.',
        incorrectPassword: 'Your current password is not correct.<br\>Please try again.',
        invalidPassword: 'Your password is not valid'
    },
    logout: {
        completeCurrentTimesheetFail: 'Your request to complete timesheet was not successful.<br/>Please try again later.',
        endTimesheetReturnFail: 'endTimesheet returned fail'
    },

    currentTimesheet: {
        completeTimesheetFail: 'Your request to complete timesheet was not successful.<br/>Please try again later.',
        endTimesheetReturnFail: 'endTimesheet returned fail'
    },
    createShift: {
        invalidRequest: 'Your request is invalid.<br/>Please check your selection.',
        startTimeSameAsEndtime: 'Start time and End time cannot be same'
    },
    biometricLogin: {
        touchIdVerifyFail: 'touch Id verified unsuccessful',
        fingerprintScanVerifyFail: 'Fingerprint scan verified unsuccessful',
        notAvailable: 'Biometric login is not available on this device',
        loginAttemptFail: ' login was not successful. Please login with your credential'
    },
    createStaff: {
        usernameNotValidated: 'You need to validate username first.',
        invalidRequest: 'Your request is invalid.<br/>Please check the mandotory fields.',
        invalidMyobCardNumber: 'MYOB Card Number should be number',
        invalidEmail: 'Email address is not valid',
        invalidUsername: 'Username is not valid',
        invalidAddress: 'Street, Suburb and Postcode are required for Home Address.'
    }
};

export const apiErrorCode = {
    userNotFound: 'Error211-UserNotFound',
    noSessionFoundError: 'Error312-NoSessionFoundError',
    changePasswordError: 'Error231-ChangePasswordError',
    shiftAlreadyExistError: 'Error414-ShiftAlreadyExistError',
    sameTimesheetAlreadyExistError: 'Error518-SameTimesheetAlreadyExistError',
    emailAlreadyRegisteredError: 'Error371-EmailAlreadyRegisteredError',
};

