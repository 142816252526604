import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { page } from 'src/app/config/constant';
import { SessionData } from 'src/app/models/session-data';
import { SessionService } from 'src/app/services/api/session.service';
import { LoginPage } from 'src/app/pages/login/login.page';
import { generalConstants } from 'src/app/config/constant';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  isWebBrowser: boolean;
  validatedOnLoad: boolean;
  alreadyLocked: boolean;
  currentSession: SessionData;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private sessionService: SessionService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.alreadyLocked = null;
      this.isWebBrowser = this.platform.platforms().includes(generalConstants.platformWeb);
      this.validateOnLoad();

      this.platform.pause.subscribe(() => {
        this.validateLogin();
      });
    });
  }

  validateOnLoad(): void {
    // on app load, lock app if session exist
    if (this.isWebBrowser) {
      console.log('[AppComponent.validateOnLoad] skipping validateOnLoad for web browser');
    }
    else if (this.validatedOnLoad) {
      console.log('[AppComponent.validateOnLoad] already validated on load');
    }
    else {
      this.sessionService.getSession().then(session => {
        console.log('[AppComponent.validateOnLoad] currentSession ' + (session ? session.sessionId : null));
        this.currentSession = session;
        if (this.currentSession) {
          this.lockApp();
        }
      });
      this.validatedOnLoad = true;
    }
  }

  validateLogin(): void {
    if (this.isNonSessionPage()) {
      console.log('[AppComponent.validateLogin] skipping lock for ' + this.router.url);
    }
    else if (!this.alreadyLocked) {
      if (this.isWebBrowser) {
        console.log('[AppComponent.validateLogin] skipping lock for web browser');
      }
      else {
        this.lockApp();
      }
    }
  }

  async lockApp() {
    console.log('[AppComponent.lockApp] locking app at url: ' + this.router.url);

    const modal = await this.modalController.create({
      component: LoginPage,
      backdropDismiss: false,
      componentProps: {
        isAppPaused: true
      }
    });
    this.alreadyLocked = true;

    modal.onDidDismiss().then((data: any) => {
      console.log('[AppComponent.lockApp.onDidDismiss] app unlocked');
      this.alreadyLocked = false;

      if (this.isNonSessionPage()) {
        if (this.currentSession) {
          console.log('[AppComponent.lockApp.onDidDismiss] redirect for non session page');
          if (this.currentSession.hasTemporaryPassword) {
            this.router.navigate([page.password]);
          }
          else {
            this.router.navigate([page.timesheet]);
          }
        }
        else {
          console.log('[AppComponent.lockApp.onDidDismiss] no redirect for non session page without session');
        }
      }
     });
    modal.present();
  }

  private isNonSessionPage(): boolean {
    return this.router.url == '/' || this.router.url == '/reset-password';
  }
}
