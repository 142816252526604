import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { apiEndpoint } from 'src/app/config/api-endpoint';
import { storageKey } from 'src/app/config/constant';
import { DeviceInfo } from 'src/app/models/device-info';
import { SessionData } from 'src/app/models/session-data';
import { ServiceResponse } from 'src/app/models/service-response';
import { LoginRequest } from 'src/app/models/login-request';

import { HttpService } from 'src/app/services/common/http.service';
import { StorageService } from 'src/app/services/common/storage.service';

@Injectable({
  providedIn: 'root'
})

export class SessionService {

  baseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpService,
    private storageService: StorageService
  ) {}
    
  validateSession(session: SessionData): Observable<ServiceResponse<boolean>> {
    if (!session) {
      console.log('[SessionService.validateSession] null session');
      return this.getFailResponse<boolean>();
    }
    else {
      const path = this.baseUrl + apiEndpoint.session.validation;
      return this.http.post<ServiceResponse<boolean>>(path, session);
    }
  }
    
  refreshToken(session: SessionData): Observable<ServiceResponse<string>> {
    if (!session) {
      console.log('[SessionService.extendSession] null session');
      return this.getFailResponse<string>();
    }
    else {
      const path = this.baseUrl + apiEndpoint.session.refreshToken;
      return this.http.post<ServiceResponse<string>>(path, session);
    }
  }
  
  login(loginRequest: LoginRequest): Observable<ServiceResponse<SessionData>> {
    const path = this.baseUrl + apiEndpoint.session.login;
    return this.http.post<ServiceResponse<SessionData>>(path, loginRequest);
  }
  
  validateLogin(loginRequest: LoginRequest, session: SessionData): Observable<ServiceResponse<boolean>> {
    const path = this.baseUrl + apiEndpoint.session.validateLogin;
    return this.http.post<ServiceResponse<boolean>>(path, loginRequest, session);
  }
  
  biometricLogin(lastSession: SessionData, loginType: string): Observable<ServiceResponse<SessionData>> {
    const path = this.baseUrl + apiEndpoint.session.biometricLogin + loginType;
    return this.http.post<ServiceResponse<SessionData>>(path, lastSession);
  }
  
  logout(session: SessionData): Observable<ServiceResponse<boolean>> {
    const path = this.baseUrl + apiEndpoint.session.logout;
    return this.http.post<ServiceResponse<boolean>>(path, {}, session);
  }
  
  postDeviceInfo(deviceInfo: DeviceInfo, session: SessionData): Observable<ServiceResponse<boolean>> {
    const path = this.baseUrl + apiEndpoint.session.deviceInfo;
    return this.http.post<ServiceResponse<boolean>>(path, deviceInfo, session);
  }
  
  async saveSession(session: SessionData, last: boolean = false): Promise<void> {
    await this.storageService.store<SessionData>((last ? storageKey.lastSession : storageKey.sessionData), session);
  }

  async getSession(last: boolean = false): Promise<SessionData> {
    return await this.storageService.get<SessionData>(last ? storageKey.lastSession :storageKey.sessionData);
  }

  async removeSession(last: boolean = false): Promise<void> {
    await this.storageService.remove(last ? storageKey.lastSession : storageKey.sessionData);
  }
  
  async saveDeviceInfo(deviceInfo: DeviceInfo): Promise<void> {
    await this.storageService.store<DeviceInfo>(storageKey.deviceInfo, deviceInfo);
  }

  async getDeviceInfo(): Promise<DeviceInfo> {
    return await this.storageService.get<DeviceInfo>(storageKey.deviceInfo);
  }

  async removeDeviceInfo(): Promise<void> {
    await this.storageService.remove(storageKey.deviceInfo);
  }

  private getFailResponse<T>(): Observable<ServiceResponse<T>> {
    return new Observable(observer => {
      const response = new ServiceResponse<T>();
      observer.next(response);
      observer.complete();
    });
  }
}
