import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor() {}

  async store<T>(storageKey: string, value: T): Promise<void> {
    const encryptedValue = btoa(escape(JSON.stringify(value)));
    await Storage.set({
      key: storageKey,
      value: encryptedValue
    });
  }

  async get<T>(storageKey: string) : Promise<T> {
    const ret = await Storage.get({ key: storageKey });
    if (!ret.value) {
      return null;
    }
    return JSON.parse(unescape(atob(ret.value)));
  }

  async remove(storageKey: string): Promise<void> {
    await Storage.remove({ key: storageKey });
  }

  async clear(): Promise<void> {
    await Storage.clear();
  }
}