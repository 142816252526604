import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { generalSetting } from 'src/app/config/settings';
import { apiEndpoint } from 'src/app/config/api-endpoint';
import { storageKey } from 'src/app/config/constant';
import { SessionData } from 'src/app/models/session-data';
import { ServiceResponse } from 'src/app/models/service-response';
import { Store } from 'src/app/models/store';
import { HttpService } from 'src/app/services/common/http.service';
import { StorageService } from 'src/app/services/common/storage.service';
import { text } from 'src/app/config/text';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  baseUrl = environment.apiBaseUrl;

  constructor(
    public http: HttpService,
    private storageService: StorageService
  ) {}

  getStore(storeId: number, session: SessionData): Observable<ServiceResponse<Store>> {
    const path = this.baseUrl + apiEndpoint.store.store.replace('{storeId}', storeId.toString());
    return this.http.get<ServiceResponse<Store>>(path, null, session);
  }

  getStores(session: SessionData, jobIds: string): Observable<ServiceResponse<Array<Store>>> {
    const path = this.baseUrl + apiEndpoint.store.stores.replace('{jobIds}', jobIds);
    return this.http.get<ServiceResponse<Array<Store>>>(path, null, session);
  }

  getStoreByGeocode(lat: number, lon: number, session: SessionData): Observable<ServiceResponse<Store>> {
    const path = this.baseUrl + apiEndpoint.store.geocode + `?lat=${lat}&lon=${lon}&distance=${generalSetting.allowedDistanceFromStore}`;
    return this.http.get<ServiceResponse<Store>>(path, null, session);
  }

  async saveSelectedStore(selectedStore: Store): Promise<void> {
    await this.storageService.store<Store>(storageKey.selectedStore, selectedStore);
  }

  async getSelectedStore(): Promise<Store> {
    return await this.storageService.get<Store>(storageKey.selectedStore);
  }

  async removeSelectedStore(): Promise<void> {
    await this.storageService.remove(storageKey.selectedStore);
  }

  getStoreName(storeBrand: string, storeName: string, storeId: number = null): string {
    const brandName = storeBrand == 'Supermarket' ? 'SMKT' : storeBrand;
    return (!storeId ? `${text.common.storeNamePreffix} ` : '') +
      `${storeName} ${brandName}` +
      (storeId ? ` [${storeId}]`  : '');
  }
}
