import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { generalSetting } from 'src/app/config/settings';
import { text } from 'src/app/config/text';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading: boolean;
  text: any;

  constructor(
    public loadingController: LoadingController
  ) {
    this.text = text.service.loading;
  }

  async present(caller: string): Promise<void> {
    try {
      this.isLoading = true;

      const loadingOption = {
        message: this.text.loadingMessage,
        duration: generalSetting.loadingDuration
      };

      return await this.loadingController.create(loadingOption).then(a => {
        a.present().then(() => {
          if (this.isLoading) {
            console.log('[LoadingService.present] presented by ' + caller);
          }
          else {
            a.dismiss().then(() => console.log('[LoadingService.present] aborted by ' + caller));
          }
        });
      });
    }
    catch(error) {
      console.log(`[LoadingService.present] caller: ${caller}, error: ${error && error.message ? error.message : error}`);
    }
  }

  async dismiss(caller: string) {
    try {
      this.isLoading = null;
      return await this.loadingController.dismiss().then(() => console.log('[LoadingService.dismiss] dismissed by ' + caller));
    }
    catch(error) {
      console.log(`[LoadingService.dismiss] caller: ${caller}, error: ${error.message}`);
    }
  }
}