import { imageSetting } from './settings';
import { generalSetting } from 'src/app/config/settings';
import { numberWithCommas } from 'src/app/util/common-util';

export const text = {
    common: {
        loadingTitle: 'loading...',
        greeting: 'Hi ',
        systemErrorTitle: 'System Error',
        systemErrorText: '<p>We are very sorry but it looks like there is network problem with connecting the server.</p><p>Please try again later.</p>',
        topLogoPath: imageSetting.baseUrl + imageSetting.noxanLogoTop,
        topLogoText: 'NOXAN',
        storeNamePreffix: 'Woolworths',
        mandatoryMark: '*'
    },
    page: {
        home: {
            tabs: {
                timesheet: 'timesheet',
                summary: 'summary',
                signoff: 'sign-off',
                password: 'password',
                logout: 'logout',
                admin: 'admin'
            }
        },
        login: {
            pageTitle: 'Login',
            logoAlt: 'Noxan (Aust\') Pty Ltd.',
            footerText: 'Copyright &copy; {year} Noxan (Aust\') Pty Ltd.<br/>All rights Reserved.',
            link: {
                resetPassword: 'Reset Password'
            }
        },
        resetPassword: {
            pageTitle: 'Reset Password',
            introText: 'Enter your email to reset password',
            link: {
                login: 'Back to login'
            },
            form: {
                email: 'Email',
                emailError: 'Please enter a valid email',
                resetPasswordButton: 'Reset Password'
            },
            result: {
                success: 'Your password reset request has been successfully processed.<br/>Please check your email to reset password.',
                fail: 'We are sorry but your password reset request was not successful.<br/>Please try again later.'
            }
        },
        timesheet: {
            pageTitle: 'Timesheet',
            subtitle: '',
            yourLocation: 'Your Location',
            startWorkButton: 'Start Work',
            startDateSelectionButton: 'Select Date',
            createShiftText: 'There is no shift found.<br/>Please add a new shift for your work',
            addShiftText: 'If your shift is not found,<br/>please add a new shift.',
            createShiftButton : 'Add Shift',
            deleteShiftButton : 'Delete Selected Shift',
            shiftDeleted : 'Shift has been successfully deleted',
            deleteShiftFailed : 'Deleting Shift failed',
            clearSelectedStoreButton: 'Clear Selected Store',
            alert: {
                startWork: {
                    header: 'Start your work?',
                    message: '',
                    button: {
                        cancel: 'cancel',
                        ok: 'start'
                    }
                },
                deleteShift: {
                    header: 'Delete selected shift?',
                    message: '',
                    button: {
                        cancel: 'cancel',
                        ok: 'delete'
                    }
                }
            },
            manualDate: {
                subtitle: 'Manual Start Date Selection',
                introText: 'If you need to choose a start date manually, please click the button below and choose date, time and provide the reason.',
                chooseButton: 'Choose Manual Start Date',
                cancelButton: 'Cancel Manual Start Date',
                saveButton: 'Start Work',
                dateLabel: 'Date',
                timeLabel: 'Time',
                manualStartReasonLabel: 'Reason',
                alert: {
                    header: 'Start timesheet with the selected finish date?',
                    message: '',
                    note: 'note',
                    button: {
                        cancel: 'cancel',
                        ok: 'start'
                    }
                }
            }
        },
        summary: {
            pageTitle: 'Summary of your work',
            introText: 'The following is the weekly summary of your work completed.',
            subtitle: ''
        },
        signoff: {
            pageTitle: 'Weekly Sign-Off',
            introText: 'The following is the weekly summary of your work completed.',
            subtitle: ''
        },
        password: {
            pageTitle: 'Change Password',
            subtitle: 'Change your password',
            temporaryPassword: 'Please change temporary password',
            form: {
                currentPassword: 'Current Password',
                newPassword: 'New Password',
                confirmPassword: 'Confirm Password',
                passwordError: 'Password needs to be at least 8 characters and less than 20 characters.',
                confirmPasswordError: 'Confirm Password does not match New Password',
                changePasswordButton: 'Change Password'
            },
            result: {
                success: 'Your password has been successfully changed.',
                fail: 'We are sorry but your change password request was not successful.<br/>Please try again later.'
            }
        },
        logout: {
            pageTitle: 'Logout',
            subtitle: 'Logout from Noxan Timesheet',
            noCurrentTimesheetText: 'Currently, there is no incomplete timesheet.',
            logoutButton: 'Logout',
            alert: {
                logout: {
                    header: `Logout from ${generalSetting.appName}?`,
                    message: '',
                    button: {
                        cancel: 'cancel',
                        ok: 'logout'
                    }
                }
            }
        },
        admin: {
            pageTitle: 'Admin Page',
            intro: '',
            addStaffButton: 'Add Staff'
        }
    },

    component: {
        loginForm: {
            introText: 'Sign in with your login credentials',
            emailOrUsername: 'Email or Username',
            emailorUsernameError: 'Please enter a valid email or username',
            password: 'Password',
            passwordError: 'Please enter a valid password',
            loginButton: 'Login'
        },
        biometricLogin: {
            biometric: 'Biometric',
            touchIdTitle: 'Noxan Timesheet Login',
            fingerprintScanTitle: 'Noxan Timesheet Login',
            scanText: ' Scan',
            buttonSuffix: ' Login'
        },
        currentTimesheet: {
            title: 'Current Timesheet',
            breakIntroText: 'You have the following incomplete timesheet. Please choose to finish work once your shift ends.',
            resumeIntroText: 'You have the following paused timesheet. Please choose to resume or complete it.',
            storeNameLabel: 'Store',
            startDateLabel: 'Started',
            complete: {
                button: 'Finish Work',
                timesheetCompleted: 'Your work has been saved',
                alert: {
                    header: 'Finish current work?',
                    message: '',
                    note: 'note',
                    button: {
                        cancel: 'cancel',
                        ok: 'finish'
                    }
                }
            },
            worklog: {
                introText: 'Last activity:',
                atText: ' at ',
                pauseButton: 'Break',
                resumeButton: 'Resume Work',
                workPaused: 'Your current work has been paused',
                workResumed: 'Your current work has beeen resumed',
                alert: {
                    pauseHeader: 'Break from work?',
                    resumeHeader: 'Resume your work?',
                    message: '',
                    note: 'note',
                    button: {
                        cancel: 'cancel',
                        pauseOk: 'break',
                        resumeOk: 'resume'
                    }
                }
            },
            manualDate: {
                subtitle: 'Submit Finish Work manually',
                introText: 'If you have not entered your finish work, click below to submit manually with the reason.',
                chooseButton: 'Submit Finish Work manually',
                cancelButton: 'Cancel Finish Work manually',
                saveButton: 'Finish Work',
                dateLabel: 'Date',
                timeLabel: 'Time',
                manualEndReasonLabel: 'Reason',
                alert: {
                    header: 'Finish timesheet with the selected finish date?',
                    message: '',
                    note: 'note',
                    button: {
                        cancel: 'cancel',
                        ok: 'Save'
                    }
                }
            }
        },
        googleMap: {
        },
        storePicker: {
            noStoreFound: `No stores found within ${numberWithCommas(generalSetting.allowedDistanceFromStore)} metres from your current location. Please select a store manually.`,
            addressLabel: 'Address',
            phoneLabel: 'Phone',
            locatedStore: {
                title: 'Located Store',
                introText: `The following store has been located within ${numberWithCommas(generalSetting.allowedDistanceFromStore)} metres from your current location.`
            },
            selectedStore: {
                title: 'Select Store',
                selectedTitle: 'Selected Store',
                introText: 'You have selected the following store manually.',
                changeButton: 'Change Store',
                selectButton: 'Select Store'
            },
            picker: {
                button: {
                    cancel: 'cancel',
                    select: 'select'
                }
            }
        },
        shiftPicker: {
            title: '',
            noShiftFound: 'There is no shift found for the selected store.',
            introText: 'Please select your shift from the followings.',
            selectShiftLabel: 'Roster Date',
            resetButton: 'Reset',
            previousDayButton: 'previous day',
            todayButton: 'today',
            nextDayButton: 'next day'
        },
        createShift: {
            title: 'Add New Shift',
            closeButton: 'Close',
            shiftDate: 'Shift Date',
            shiftDateNote: 'NOTE: This shift will be applied by the day of week.',
            introText: 'Please provide job, title, start and finish time for the shift.',
            selectedJob: 'Job',
            shiftTitle: '',
            minuteValues: '0,15,30,45',
            createShiftButton: 'Add Shift',
            successfullyCreated: 'A new shift has been successfully created',
            shiftAlreadyExist: 'The same shift already exists in the database',
            label: {
                title: 'Title',
                job: 'Job',
                start: 'Start',
                end: 'Finish',
                note: 'Note',    
            }
        },
        summaryTimesheet: {
            noDataText: 'No data for your work for the selected period.',
            dataFoundText: 'Click a timesheet to view more details',
            periodText: 'Period', 
            label: {
                date: 'Date',
                store: 'Store',
                start: 'Start',
                end: 'Finish',
                duration: 'Duration',
                hours: 'hours',
                previousWeek: 'previous',
                nextWeek: 'next'
            }
        },
        summaryDetails: {
            introText: 'Timesheet Details:',
            worklogsIntroText: 'Work Log Details:',
            label: {
                jobTitle: 'Work Title',
                shiftStart: 'Shift Start',
                shiftEnd: 'Shift Finish',
                workStart: 'Work Start',
                workEnd: 'Work Finish',
                manualStartReason: 'Reason for Manual Start Date',
                manualEndReason: 'Reason for Manual Finish Date',
                timesheetNote: 'note',
                logDate: 'Date',
                worklogType: 'Log Type',
                worklogNote: 'Note'
            }
        },
        weeklySignoff: {
            noDataText: 'No data for your work for the selected period.',
            periodText: 'Period',
            signedOffText: '<p>You already signed off the above timesheet at <b>{signedOffDate}</b>.</p><p>To rework on this timesheet, please click <b>Rework Timesheet</b> button below.</p>',
            signOffText: 'Check the box to confirm that the above information is accurate and submit your timesheet.',
            submitButton: 'Submit Timesheet',
            reworkButton: 'Rework Timesheet',
            submitted: 'Your timesheet has been successfully submitted',
            reworkDone: 'Your timesheet is ready for sign-off again',
            timesheetDeleted: 'The timesheet has been successfully deleted',
            label: {
                date: 'Date',
                store: 'Store',
                start: 'Start',
                end: 'Finish',
                delete: 'Delete',
                duration: 'Duration',
                hours: 'hours',
                previousWeek: 'previous',
                nextWeek: 'next'
            },
            alert: {
                submit: {
                    header: 'Submit Timesheet?',
                    message: '',
                    note: 'note',
                    button: {
                        cancel: 'cancel',
                        ok: 'submit'
                    }
                },
                delete: {
                    header: 'Delete timesheet for {timesheetDate}?',
                    message: '',
                    button: {
                        cancel: 'cancel',
                        ok: 'delete'
                    }
                },
                rework: {
                    header: 'Rework on this timesheet?',
                    message: '',
                    button: {
                        cancel: 'cancel',
                        ok: 'rework'
                    }
                }
            }
        },
        createStaff: {
            title: 'Add New Staff',
            introText: 'Please validate the email first to add a new staff',
            mandatoryText: ' marked fields are mandatory.',
            validateEmailButton: 'Validate Email',
            validateUsernameButton: 'Validate Username',
            createStaffButton: 'Create Staff',
            createAnotherButton: 'Create Another Staff',
            closeButton: 'Close',
            successfullyCreated: 'A new staff has been successfully created with username: ',
            staffAlreadyExist: 'Staff with the same email already exists in the database',
            usernameNotAvailable: 'Username already exists so please try a different username',
            jobList: 'Job',
            selectJob: 'Select Job(s)',
            personalDetails: 'Personal Details',
            address: 'Home Address',
            otherDetails: 'Other Details',
            staffCreatedText: 'The following staff has been successfully created:',
            label: {
                username: 'Username',
                password: 'Password',
                title: 'Title',
                firstName: 'First Name',
                middleName: 'Middle Name',
                lastName: 'Last Name',
                email: 'Email',
                mobile: 'Mobile',
                gender: 'Gender',
                dateOfBirth: 'Date of Birth',
                myobCardNumber: 'MYOB Card Number',
                pegasusNumber: 'Pegasus Number',
                cardExpiryDate: 'Card Expiry Date',
                street: 'Street',
                suburb: 'Suburb',
                postcode: 'Postcode',
                state: 'State'
            }
        },
    },

    service: {
        loading: {
            loadingMessage: 'Please wait...'
        }
    }
};
