import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { generalSetting } from 'src/app/config/settings';
import { apiEndpoint } from 'src/app/config/api-endpoint';
import { ReferenceData } from 'src/app/models/reference-data';
import { SessionData } from 'src/app/models/session-data';
import { LogRequest } from 'src/app/models/log-request';
import { HttpService } from 'src/app/services/common/http.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  baseUrl = environment.apiBaseUrl;

  constructor(
    public http: HttpService
  ) { }

  getReferenceData(): Observable<ReferenceData> {
    const path = this.baseUrl + apiEndpoint.system.referenceData;

    return this.http.get<ReferenceData>(path);
  }

  postLog(className: string, memberName: string, error: any, errorCode: string, session: SessionData, isWarning: boolean = false): void {
    try {

      const message = error.message ?? error;
      console.log(`[SystemService.postLog] className: ${className} | memberName: ${memberName} | ` +
         (errorCode ? `errorCode: ${errorCode} | ` : '') +
         'message: ' + message + ' | isWarning: ' + isWarning);

      const logRequest = new LogRequest();
      logRequest.sourceContext = generalSetting.logSource + `.${className}`;
      logRequest.memberName = memberName;
      logRequest.message = message ?? errorCode;
      logRequest.errorCode = errorCode;
      logRequest.isWarning = isWarning;

      const path = this.baseUrl + apiEndpoint.system.postLog;
      
      this.http.post<boolean>(path, logRequest, session).subscribe(res => {});
    }
    catch(error) {
      console.log('[SystemService.postLog] error: ' + error);
    }
  }

}
