import { FormControl, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

export class FormValidatorService extends Validators {

    static required(c: FormControl): ValidationErrors | null {
        const value = c.value;

        if (value && typeof value === 'string' && value.trim && !value.trim()) {
            return { required: true };
        }

        return super.required(c);
    };

    static email(c: FormControl): ValidationErrors | null {
        /* tslint:disable:max-line-length */
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegex.test(c.value) ? null : { email: { value: false }};
    };

    static australiaPhoneValidator(c: FormControl): ValidationErrors | null {
        const phoneRegex = /^04\d{8}$/;
        return phoneRegex.test(c.value) ? null : { invalidPhoneNumber: { valid: false }};
    }

    static noneValidator(c: FormControl): ValidationErrors | null {
        if (!c.value) {
            return null;
        }

        return c.value !== 'None' ? null : { nonValidator: { valid: false }};
    }

    static number(c: FormControl): ValidationErrors | null {
        if (!c.value) {
            return null;
        }

        const numberRegex = /^\d+$/;
        return numberRegex.test(c.value) ? null : { numberic: { valid: false }};
    }

    static hasLetters(c: FormControl): ValidationErrors | null {
        if (!c.value) {
            return null;
        }

        const lettersRegex = /^[a-zA-Z !\\"#$%&'()*+,\-.\/:;<=>?@\[\]^_`{|}~]*$/;
        return lettersRegex.test(c.value) ? null : { hasLetters: { valid: false }};
    }

    static requiredLengthValidator(requiredLength: string): ValidatorFn {
        const checkingPoint = requiredLength.split(',');
        return function (c: FormControl): ValidationErrors | null {
            const isValid = checkingPoint.indexOf(`${c.value !== null ? c.value.length : 0}`) > -1;
            return isValid ? null : { requiredLength: { valid: false, length: requiredLength }};
        };
    }

    static noResultFound(c: FormControl): ValidationErrors | null {
        return !c.value !== null ? null : { noResultFound: { valid: false }};
    }
    
    static nameValidator(c: FormControl): ValidationErrors | null {
        if (!c.value) {
            return null;
        }

        const nameRegex = /^([^0-9]*)$/;
        return nameRegex.test(c.value) ? null : { name: { valid: false }};
    }
    
    static hasSpecialChars(c: FormControl): ValidationErrors | null {
        if (!c.value) {
            return null;
        }

        const lettersRegex = /[ !@#$%^&*()+\=\[\]{};':"\\|,<>\/?`]/;
        return !lettersRegex.test(c.value) ? null : { invalidCharacter: { valid: false }};
    }
    
    static checkBoxValidator(c: FormControl): ValidationErrors | null {
        return c.value !== null && c.value ? null : { tick: { valid: false }};
    }
};