import { Component, Input } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Platform, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { page, responseStatus, generalConstants } from 'src/app/config/constant';
import { errorCode, errorMessage, apiErrorCode } from 'src/app/config/error';
import { mapSetting, imageSetting } from 'src/app/config/settings';
import { text } from 'src/app/config/text';

import { DeviceInfo } from 'src/app/models/device-info';
import { ErrorResponse } from 'src/app/models/error-response';
import { GeoCoord } from 'src/app/models/geo-coord';
import { SessionData } from 'src/app/models/session-data';
import { ServiceResponse } from 'src/app/models/service-response';

import { LoadingService } from 'src/app/services/common/loading.service';
import { SessionService } from 'src/app/services/api/session.service';
import { StoreService } from 'src/app/services/api/store.service';
import { SystemService } from 'src/app/services/api/system.service';
import { ToastService } from 'src/app/services/common/toast.service';
import { BiometricLoginComponent } from 'src/app/components/biometric-login/biometric-login.component';
import { toIsoDate } from 'src/app/util/date-util';

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
})
export class LoginPage {

  @Input() isAppPaused: boolean;

  systemError: boolean;
  text: any;
  showPage: boolean;
  session: SessionData;
  lastSession: SessionData;
  currentDeviceInfo: DeviceInfo;
  currentLocation: GeoCoord;

  isWebBrowser: boolean;

  constructor(
    private modalController: ModalController,
    private device: Device,
    private geolocation: Geolocation,
    private platform: Platform,
    private router: Router,
    private loadingService: LoadingService,
    private sessionService: SessionService,
    private storeService: StoreService,
    private systemService: SystemService,
    private toastService: ToastService,
    private biometricLogin: BiometricLoginComponent
  ) {
    this.text = text.page.login;
    this.text.common = text.common;
  }

  ionViewDidEnter(): void {
    this.init();

    this.platform.ready().then(() => {
      // get location to get the permission
      this.getCurrentLocation();
      this.loadPageData();
    });
  }

  init(): void {
    this.systemError = null;
    this.showPage = null;
    this.session = null;
    this.currentDeviceInfo = null;
    this.isWebBrowser = null;
    console.log('[LoginPage.init] initiated');
  }

  loadPageData(): void {
    console.log('[LoginPage.loadPageData] isAppPaused: ' + this.isAppPaused);

    this.sessionService.getSession().then(currentSession => {
      this.session = currentSession;
      console.log('[LoginPage.loadPageData] currentSession: ' + (currentSession ? toIsoDate(currentSession.expiryDate) : null));

      this.sessionService.getSession(true).then(lastSession => {
        this.lastSession = lastSession;
        console.log('[LoginPage.loadPageData] lastSession: ' + (lastSession ? toIsoDate(lastSession.expiryDate) : null));

        this.sessionService.validateSession(this.session).subscribe((response: ServiceResponse<boolean>) => {
          // if app is not paused (!= modal) and session is valid, go to the session page
          if (!this.isAppPaused && response.data) {
            this.goToSessionPage(this.session);
          }
          else {
            this.showPage = true;
            this.setDeviceInfo();
          }
        },
        (error: any) => {
          //system error and not much we can do!
          this.systemError = true;
          console.log('[LoginPage.loadPageData] system error: ' + error.message);
        });
      });
    });
  }

  getCurrentLocation(): void {
    this.geolocation.getCurrentPosition(mapSetting.geolocationOptions).then(position => {
      this.currentLocation = new GeoCoord(position.coords.latitude, position.coords.longitude);
      console.log(`[LoginPage.showCurrentLocation] current location: latitude: ${this.currentLocation.latitude}, longitude: ${this.currentLocation.longitude}`);
     },
     (error: any) => {
       console.log('[LoginPage.showCurrentLocation] getCurrentPosition error: ' + `error.code: ${error.code}, error.message: ${error.message}`);
     });
  }

  handleLoginResponse(response: ServiceResponse<SessionData>, memberName: string): void {
    if (response) {
      // if all good, go to store the session and timesheet
      // if responded with error, log error and show message
      // otherwise, show login fail error
      if (response.status === responseStatus.sucess) {
        this.sessionService.saveSession(response.data);
        this.sessionService.saveSession(response.data, true);
        console.log('[LogoutPage.handleLoginResponse] current & last session saved');

        this.storeService.removeSelectedStore();
        console.log('[LogoutPage.handleLoginResponse] selected store removed');

        if (!this.isWebBrowser) {
          this.postDeviceInfo(response.data);
        }

        this.loadingService.dismiss('LoginPage.handleLoginResponse');
        this.goToSessionPage(response.data);
      }
      else if (response.error) {
        this.handleLoginResponseWithError(response.error);
      }
    }
    else {
      this.loadingService.dismiss('LoginPage.handleLoginResponse.response.null');
      const message = 'loginResponse is empty';
      this.systemService.postLog('LoginPage', memberName, message, errorCode.common.emptyResponseError, null);
      this.toastService.presentToast(errorMessage.login.loginFail);
    }
  }

  handleLoginResponseWithError(error: ErrorResponse): void {
    console.log('[LoginPage.handleLoginResponseWithError] error.code: ' + error.code);
    this.loadingService.dismiss('LoginPage.handleLoginResponseWithError.response.error');
    
    // if no sesion is found, this must be from biomegric error so remove current and last session
    let message = error.code === apiErrorCode.userNotFound ? errorMessage.login.userNotFound : errorMessage.login.loginFail;
    if (error.code === apiErrorCode.noSessionFoundError) {
      message = this.biometricLogin.getNoSessionErrorMessage();
      this.logoutOnAppPaused(page.login);
    }
    this.toastService.presentToast(message);
  }

  setDeviceInfo(): void {
    this.isWebBrowser = this.platform.platforms().includes(generalConstants.platformWeb);

    if (this.isWebBrowser) {
      console.log('[LoginPage.setDeviceInfo] skipping for not mobile device');
    }
    else {
      this.sessionService.getDeviceInfo().then((response: DeviceInfo) => {
        console.log(`[LoginPage.setDeviceInfo] setDeviceInfo.model: ${response ? response.model : null}`);
        this.currentDeviceInfo = response;
      },
      (error: any) => {
        this.systemService.postLog('LoginPage', 'setDeviceInfo', error, errorCode.login.getDeviceInfoError, this.lastSession);
      });
    }
  }

  postDeviceInfo(session: SessionData): void {
    try {
      // post device info only if not brower and new device
      if (!this.currentDeviceInfo || 
          this.currentDeviceInfo.platform != this.device.platform ||
          this.currentDeviceInfo.manufacturer != this.device.manufacturer ||
          this.currentDeviceInfo.model != this.device.model ||
          this.currentDeviceInfo.version != this.device.version
        ) {
        const deviceInfo = new DeviceInfo();
        deviceInfo.platform = this.device.platform;
        deviceInfo.manufacturer = this.device.manufacturer;
        deviceInfo.model = this.device.model;
        deviceInfo.version = this.device.version;

        this.sessionService.postDeviceInfo(deviceInfo, session).subscribe(
          response => console.log('[LoginPage.postDeviceInfo] device info posted: ' + response),
          error => console.log('[LoginPage.postDeviceInfo] posting device info error: ' + error
        ));
        this.sessionService.saveDeviceInfo(deviceInfo);
      }
    }
    catch(error) {
      console.log('[LoginPage.postDeviceInfo] error: ' + error.message);
    }
  }

  logoutOnAppPaused(toPage: string): void {
    this.modalController.dismiss();
    this.loadingService.present('LoginPage.logoutOnAppPaused').then(() => {
      if (this.session) {
        this.sessionService.logout(this.session).subscribe((response : ServiceResponse<boolean>) => {
          this.storeService.removeSelectedStore();
          console.log('[LogoutPage.logoutOnAppPaused] selected store removed');
          // not much we can do about the response
        },
        // logout error
        (error: any) => {
          this.systemService.postLog('LoginPage', 'logoutOnAppPaused', error, errorCode.login.logoutToResetPasswordError, this.session);
          this.loadingService.dismiss('LoginPage.logoutOnAppPaused.error');
        });

        // set the current session as the last session for next login
        this.sessionService.saveSession(this.session, true).then(() => {
          console.log('[LoginPage.logoutOnAppPaused] current session saved as last session');
        },
        (error: any) => {
          this.systemService.postLog('LoginPage', 'logoutOnAppPaused', error, errorCode.login.removeLastSessionError, this.session);
          this.loadingService.dismiss('LoginPage.logoutOnAppPaused.error');
        });
      }

      this.sessionService.removeSession().then(() => {
        this.loadingService.dismiss('LoginPage.logoutOnAppPaused');
        this.router.navigate([toPage]);
      },
      (error: any) => {
        this.systemService.postLog('LoginPage', 'logoutOnAppPaused', error, errorCode.login.removeCurrentSessionError, this.session);
        this.loadingService.dismiss('LoginPage.logoutOnAppPaused.error');
      });
    });
  }

  goToSessionPage(session: SessionData): void {
    if (this.isAppPaused) {
      this.modalController.dismiss();
    }
    else {
      if (session && session.hasTemporaryPassword) {
        this.router.navigate([page.password]);
      }
      else {
        this.router.navigate([page.timesheet]);
      }
    }
  }

  goToResetPassword(): void {
    this.showPage = null;

    if (this.isAppPaused) {
      this.logoutOnAppPaused(page.resetPassword);
    }

    this.router.navigate([page.resetPassword]);
  }

  onBiometricLoginResponse(loginResponse: ServiceResponse<SessionData>) {
    this.handleLoginResponse(loginResponse, 'biometricLogin');
  }

  onFormLoginResponse(loginResponse: ServiceResponse<SessionData>) {
    this.handleLoginResponse(loginResponse, 'loginForm');
  }

  getFooterText(): string {
    return this.text.footerText ? this.text.footerText.replace('{year}', (new Date().getFullYear())) : null;
  }

  getMainLogo(): string {
    return imageSetting.baseUrl + imageSetting.noxanLogoMain;
  }
}
