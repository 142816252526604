import { KeyValueType } from './../models/keyvalue-type';

export const roleType = {
    guest: 'Guest',
    staff: 'Staff',
    teamLead: 'TeamLead'
};
export const roleTypes: Array<string> = [ roleType.guest, roleType.staff, roleType.teamLead ];

export const titleType = {
    master: 'Master',
    mr: 'Mr',
    miss: 'Miss',
    mrs: 'Mrs',
    ms: 'Ms',
    other: 'Other'
};
export const titleTypes: Array<string> = [ titleType.master, titleType.mr, titleType.miss, titleType.mrs, titleType.ms, titleType.other ];

export const genderType = {
    female: 'Female',
    male: 'Male',
    other: 'Other'
};
export const genderTypes: Array<string> = [ genderType.female, genderType.male, genderType.other ];

export const addressType = {
    home: 'Home',
    postal: 'Postal',
    business: 'Business',
    other: 'Other'
};
export const addressTypes: Array<string> = [ addressType.home, addressType.postal, addressType.business, addressType.other ];

export const stateType = {
    australianCapitalTerritory: 'ACT',
    newSouthWales: 'NSW',
    northernTerritory: 'NT',
    queensland: 'QLD',
    southAustralia: 'SA',
    tasmania: 'TAS',
    victoria: 'VIC',
    westernAustralia: 'WA'
};
export const states: Array<KeyValueType> = [
    new KeyValueType(stateType.australianCapitalTerritory, 'Australian Capital Territory'),
    new KeyValueType(stateType.newSouthWales, 'New South Wales'),
    new KeyValueType(stateType.northernTerritory, 'Northern Territory'),
    new KeyValueType(stateType.queensland, 'Queensland'),
    new KeyValueType(stateType.southAustralia, 'South Australia'),
    new KeyValueType(stateType.tasmania, 'Tasmania'),
    new KeyValueType(stateType.victoria, 'Victoria'),
    new KeyValueType(stateType.westernAustralia, 'Western Australia')
];

export const countryType = {
    australila: 'Australia'
};
export const Countries: Array<KeyValueType> = [
    new KeyValueType(countryType.australila, countryType.australila)
];
