export const apiEndpoint = {
    
    system: {
        referenceData: 'system/reference-data',
        postLog: 'system/log'
    },

    session: {
        validation: 'session/validation',
        refreshToken: 'session/refresh-token',
        login: 'session/login',
        validateLogin: 'session/login/validation',
        biometricLogin: 'session/login/biometric?loginType=',
        logout: 'session/logout',
        deviceInfo: 'session/device-info'
    },

    profile: {
        requestResetPassword: 'profile/password/reset/token',
        changePassword: 'profile/password'
    },

    roster: {
        base: 'roster',
        shift: 'roster/shift',
        disableShift: 'roster/shift/{shiftId}/disable'
    },

    store: {
        store: 'store/{storeId}',
        stores: 'store?jobIds={jobIds}',
        geocode: 'store/geocode'
    },

    timesheet: {
        base: 'timesheet',
        timesheetWorklogs: 'timesheet/{timesheetId}/worklogs',
        disable: 'timesheet/{timesheetId}/action/disable',
        current: 'timesheet/current',
        worklog: 'timesheet/worklog',
        get: 'timesheet/get'
    },

    submission: {
        base: 'submission',
        disable: 'submission/{submissionId}/disable',
        weeklySummary: 'submission/weekly-summary',
        get: 'submission/get'
    },

    staff: {
        base: 'staff',
        username: 'staff/username?email=',
        usernameValidation: 'staff/username/{username}/validation',
    }
};