import * as moment from 'moment';

export function toDateAsParam(date: Date): string {
    return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') : null;
};

export function toIsoDate(date: Date): string {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
};

export function toFullDate(date: Date): string {
    return date ? moment(date).format('DD MMMM YYYY, h:mm:ss a') : null;
};

export function toLongDate(date: Date): string {
    return date ? moment(date).format('DD MMM YYYY, h:mm a') : null;
};

export function toMediumDate(date: Date): string {
    return date ? moment(date).format('DD/MM/YYYY, hh:mm a') : null;
};

export function toShortDate(date: Date): string {
    return date ? moment(date).format('DD/MM/YY, hh:mm a') : null;
};

export function toShortDateOnly(date: Date): string {
    return date ? moment(date).format('DD/MM/YYYY') : null;
};

export function toShortDateWithDayOfWeek(date: Date): string {
    return date ? moment(date).format('DD/MMM') + ' ' + moment(date).format('dddd').substring(0, 3) : null;
};

export function toLongDateOnly(date: Date): string {
    return date ? moment(date).format('DD MMM YYYY') : null;
};

export function toFullDateOnly(date: Date): string {
    return date ? moment(date).format('DD MMMM YYYY') : null;
};

export function toTimeOnly(dateString: string): string {
    return dateString ? moment(dateString).format('h:mm a') : null;
};

export function toTimespan(date: Date): string {
    return date ? moment(date).format('HH:mm') + ':00' : null;
};

export function isSameTimespan(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) {
        return null;
    }

    const d1 = moment(date1);
    const d2 = moment(date2);
    return d1.hour() === d2.hour() && d1.minute() === d2.minute();
};

export function isSameDay(date1: Date, date2: Date): boolean {
    if (!date1 || !date2) {
        return null;
    }

    const d1 = moment(date1);
    const d2 = moment(date2);
    return d1.year() === d2.year() && d1.month() === d2.month() && d1.day() === d2.day();
};

export function isToday(date: Date) {
    return isSameDay(date, new Date());
};

export function toTime(timespan: string): string {
    return timespan ? moment(timespan, 'HH:mm:ss').format('h:mma') : null;
};

export function today(): string {
    return moment().format('DD MMMM YYYY');
};

export function toDateTime(date: Date, time: Date): Date {
    if (!date || !time) {
        return null;
    }

    const dateString = moment(date).format('YYYY-MM-DD');
    const timeString = moment(time).format('HH:mm:00');
    return moment(dateString + ' ' + timeString).toDate();
};

export function addDays(date: Date, days: number): Date {
    return moment(date).add(days, 'days').toDate();
};

export function isAfter(date1: Date, date2: Date): boolean {
    return date1 && date2 ? moment(date1).isAfter(moment(date2)) : null;
};

export function isBefore(date1: Date, date2: Date): boolean {
    return date1 && date2 ? moment(date1).isBefore(moment(date2)) : null;
};

export function previousDay(date: Date): Date {
    return date ? moment(date).add(-1, 'days').toDate() : null;
};

export function nextDay(date: Date): Date {
    return date ? moment(date).add(1, 'days').toDate() : null;
};

export function previousWeek(date: Date): Date {
    return date ? moment(date).add(-7, 'days').toDate() : null;
};

export function nextWeek(date: Date): Date {
    return date ? moment(date).add(7, 'days').toDate() : null;
};

export function getMonday(date: Date): Date {
    // if date is sunday, go back to previous week
    const selectedDate = moment(date).format("YYYY-MM-DD 00:00:00");
    const monday = moment(selectedDate);

    return  monday.day() === 0
        ?  monday.add(-1, 'days').day('monday').toDate()
        :  monday.day('monday').toDate();
};

export function getSunday(date: Date): Date {
    const sunday = moment(getMonday(date)).add(6, 'days');
    return moment(moment(sunday).format("YYYY-MM-DD 23:59:59")).toDate();
};

export function toTimespanDate(date: Date, timespan: string): Date {
    const dateString = moment(date).format('YYYY-MM-DD');
    const timeString = moment(dateString + ' ' + timespan).format('HH:mm:ss');
    return moment(dateString + ' ' + timeString).toDate();
};

export function getDuration(startDate: Date, endDate: Date, decimal: number): string {
    if (!startDate || !endDate) {
        return null;
    }

    const start = moment(startDate);
    const end = moment(endDate);
    let duration = moment.duration(end.diff(start));
    return duration.asHours().toFixed(decimal);
};

export function toDayOfWeek(date: Date = null): string {
    date = date ?? new Date();
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('dddd');
};

export function toDayEnum(date: Date = null): number {
    date = date ?? new Date();
    const weekDay = moment(date).isoWeekday();

    // return 0 for sunday
    return weekDay != 7 ? weekDay : 0;
};

export function getDayStart(date: Date): Date {
    const dayStart = moment(date).format("YYYY-MM-DD 00:00:00");
    return moment(dayStart).toDate();
};

export function getDayEnd(date: Date): Date {
    const dayEnd = moment(date).format("YYYY-MM-DD 23:59:59");
    return moment(dayEnd).toDate();
};
