import { Address } from 'src/app/models/address';

export function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
};

export function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
};

export function isString(value: any): boolean {
    return typeof value === 'string';
};

export function isUndefined(value: any): boolean {
    return typeof value === 'undefined';
};

export function isNull(value: any): boolean {
    return value === 'null' || value === null;
};

export function isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
};

export function isBoolean(value: any): boolean {
    return value !== null && typeof value === 'boolean';
};

export function isNumberFinite(value: any): boolean {
    return isNull(value) && isFinite(value);
};
export function getParamFromUrl(key: string, url: string): string {
    const regex = new RegExp(`[?&]${key}=([^&#]*)`, 'i');
    const results = regex.exec(url);

    return results && results.length ? results[1] : '';
};

export function allowLetterInput(e: KeyboardEvent): void {
    // if it is a number, stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
        (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }
};

export function allowNumberInput(e: KeyboardEvent) {
    if ([46, 8, 9, 27, 13].indexOf(e.keyCode) != -1 ||      // allow: backspace, delete, tab, escape, enter
        ((e.ctrlKey || e.metaKey) && (
            e.keyCode === 65 ||     // allow: ctrl+a
            e.keyCode === 67 ||     // allow: ctrl+c
            e.keyCode === 86 ||     // allow: ctrl+v
            e.keyCode === 88)       // allow: ctrl+x
        ) ||
        (e.keyCode >= 35 && e.keyCode <= 39 )   // allow: arrow keys
    ) {
        // do nothing
        return;
    }

    // if it is a number, stop the keypress
    allowLetterInput(e);
};

export function triggerInputChange(element: HTMLInputElement, value: any): void {
    element.value = value;
    element.dispatchEvent(new Event('input', { bubbles: true }));
};

export function extractDeepPropertyByMapKey(obj: any, map: string): any {
    const keys = map.split('.');
    const key = keys.shift();
    // tslink:disable:no-shadowed-variable
    return keys.reduce((prop: any, key: string) => {
        return !isUndefined(prop) && !isUndefined(prop[key]) ? prop[key] : undefined
    }, obj[key || '']);
};

export function isNullOrUndefinedOrEmpty(arg: any): boolean {
    return !arg || arg.length === 0;
};

export function getObjectInArray(list: any[], key: string, keyValue: string): any {
    const items = list.filter(item => {
        return item[key] === keyValue;
    });

    return items.length === 1 ? items[0] : null;
};

export function filterArray(list: any[], key: string, value: any): any {
    const items = list.filter(item => {
        return item[key] == value;
    })
    return items.length ? items : null;
};

export function spaceTrimmer(str: string): string {
    str = str.replace(/^\s+/, '');
    for (let i = str.length - 1; i >= 0; i--) {
        if (/\S/.test(str.charAt(i))) {
            str = str.substring(0, i + 1);
            break;
        }
    };
    return str;
};

export function isFirstOrLastCharSpace(str: string): boolean {
    return /\s$/.test(str) || /^\s/.test(str);
};

export function setFocuse(element: HTMLElement): void{
    if (element) {
        element.focus();
    }
};

export function toFullAddress(address: Address): string {
    let fullAddress: string = '';

    if (address) {
        if (address.buildingName) fullAddress += address.buildingName + ', ';
        if (address.line1) fullAddress += address.line1;
        if (address.line2) fullAddress += ' ' + address.line2;
        if (address.suburb) fullAddress += ', ' + address.suburb;
        if (address.state) fullAddress += ', ' + address.state;
        if (address.postcode) fullAddress += ' ' + address.postcode;
    }

    return fullAddress;
}

export function capitalise(value: string) {
    if (!value || !value.trim()) {
        return null;
    }
    const stringValue = value.trim();
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

export function toWords(value: string) {
    if (!value || !value.trim()) {
        return null;
    }
    return value.trim().match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isEmpty(text: string): boolean {
    return !text || text == undefined || text == '' || text.trim() == '';
}

export function isValidEmail(text: string): boolean {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(text)) {
    return true;
  }
  return false;
}