import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';

import { FormValidationRuleProvider } from './form-validation-rule.provider';
import { isNumber, isString, isObject, isBoolean, isUndefined, isNull } from 'src/app/util/common-util';

@Injectable()
export class FormControlService {
    constructor(
        private formBuilder: FormBuilder,
        private validatorProvider: FormValidationRuleProvider
    ) {}

    createFormControl(name: string, value: any, prefix = ''): FormControl {
        const validators = this.validatorProvider.getValidationRules(name, prefix);
        return new FormControl(value, validators);
    }

    createFormArray(controls, any): FormArray {
        return this.formBuilder.array(controls);
    }

    createFormGroup(): FormGroup {
        return this.formBuilder.group({});
    }

    initialiseFormGroup(parentForm: FormGroup, name: string): FormGroup {
        let form = <FormGroup>parentForm.controls[name];
        if (!form) {
            form = this.createFormGroup();
            parentForm.addControl(name, form);
        }

        return form;
    }

    removeControls(form: FormGroup, keys: any[]): void {
        keys.forEach(q => {
            form.removeControl(q.name);
        });
    }

    markFormArrayAsDirty(formArray: FormArray): void {
        formArray.controls.forEach(formGroup => this.markFormGroupAsDirty(<FormGroup>formGroup));
    }

    markFormGroupAsDirty(formgGroup: FormGroup): void {
        Object.keys(formgGroup.controls).forEach(control => {
            const ctrl = formgGroup.controls[control];
            if (ctrl.invalid) {
                if (ctrl instanceof FormArray) {
                    this.markFormArrayAsDirty(ctrl);
                }
                else if (ctrl instanceof FormGroup) {
                    this.markFormGroupAsDirty(ctrl);
                }
                else {
                    ctrl.markAsDirty();
                }
            }
        });
    }

    markFormPristine(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach(control => {
            const ctrl = formGroup.controls[control];
            if (ctrl instanceof FormArray) {
                this._markFormArrayPristine(ctrl as FormArray);
            }
            else if (ctrl instanceof FormGroup) {
                this.markFormPristine(ctrl as FormGroup);
            }
            else {
                ctrl.markAsUntouched();
                ctrl.markAsPristine();
            }
        });
    }

    _markFormArrayPristine(formArray: FormArray): void {
        formArray.controls.forEach(formGroup => this.markFormPristine(<FormGroup>formGroup));
    }

    isArrayOfString(value: string[]): boolean {
        let isArrayOfString: boolean = false;
        if (Array.isArray(value)) {
            value.forEach(item => {
                isArrayOfString = typeof item === 'string';
            });
        }
        return isArrayOfString;
    }

    // create form group with values
    createFormGroupWithValue(value: any, validatorPrefix = ''): FormGroup {
        const group = this.formBuilder.group({});

        Object.keys(value).reduce((prev, key) => {
            const controlValue = value[key];
            let control = null;

            if (
                isString(controlValue) ||
                isNumber(controlValue) ||
                isBoolean(controlValue) ||
                isUndefined(controlValue) ||
                isNull(controlValue) ||
                this.isArrayOfString(controlValue)
            ) {
                control = this.createFormControl(key, controlValue, validatorPrefix);
            }
            else if (Array.isArray(controlValue) && !this.isArrayOfString(controlValue)) {
                const childControls = controlValue.map(v => this.createFormGroupWithValue(v, validatorPrefix));
                control = this.createFormGroupWithValue(childControls);
            }
            else if (isObject(controlValue)) {
                control = this.createFormGroupWithValue(controlValue, validatorPrefix);
            }

            group.addControl(key, control);
            return group;
        }, group);

        return group;
    }

    addControlToFormGroup(formGroup: FormGroup, name: string, value: any = '', prefix = '') {
        formGroup.addControl(name, this.createFormControl(name, value, prefix));
    }
};