import { Injectable } from '@angular/core';
import { FormValidators } from './form-validators.model';
import { FormValidatorService } from './form-validator.service';


@Injectable()
export class FormValidationRuleProvider {

    getValidationRules(name: string, prefix = '') {
        const rules = FormValidators[prefix + name];
        if (!rules) {
            return null;
        }

        return rules.split('|').map((rule) => {
            if (rule.indexOf('=') > 0) {
                const ruleArr = rule.split('=');
                return this.getValidator(rule);
            }
        });
    }

    private getValidator(validatorName: string, value: string = '') {
        switch (validatorName) {
            case 'required': return FormValidatorService.required;
            case 'maxLength': return FormValidatorService.maxLength;
            case 'minLength': return FormValidatorService.minLength;
            case 'email': return FormValidatorService.email;
            case 'australiaPhoneValidator': return FormValidatorService.australiaPhoneValidator;
            case 'number': return FormValidatorService.number;
            case 'hasLetters': return FormValidatorService.hasLetters;
            case 'requiredLength': return FormValidatorService.requiredLengthValidator(value);
            case 'noResultFound': return FormValidatorService.noResultFound;
            case 'name': return FormValidatorService.nameValidator;
            case 'none': return FormValidatorService.noneValidator;
            case 'hasSpecialChars': return FormValidatorService.hasSpecialChars;
            case 'checkBoxValidator': return FormValidatorService.checkBoxValidator;
        };
    }

}